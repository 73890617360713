body {
  font-size: 1.2rem;
  color: #334155;
  font-weight: lighter;
}

h1, h2, h3, h4 {
  font-family: 'Roboto';
  font-weight: lighter;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}
